<template>
  <el-container>
    <el-main>
      <el-container>
        <el-aside width="40%"
                  style="box-shadow: 0 2px 4px rgba(0, 0, 0, .12), 0 0 6px rgba(0, 0, 0, .04);padding: 20px">
          <el-tabs value="first" style="outline: 0;">
            <el-tab-pane label="新增角色信息" name="first">
              <el-form ref="roleForm" :rules="rules" :model="roleForm" label-width="100px" size="small">
                <el-form-item label="角色代码" prop="roleCode">
                  <el-input placeholder="角色代码" v-model="roleForm.roleCode" style="width: 100%;"></el-input>
                </el-form-item>
                <el-form-item label="角色名称" prop="roleName">
                  <el-input placeholder="角色名称" v-model="roleForm.roleName" style="width: 100%;"></el-input>
                </el-form-item>
                <el-form-item label="是否启用">
                  <el-switch v-model="roleForm.isRoleEnabled" active-value="1" inactive-value="0"></el-switch>
                </el-form-item>
                <el-form-item label="描述" prop="roleDesc">
                  <el-input type="textarea" v-model="roleForm.roleDesc"></el-input>
                </el-form-item>
                <!--<el-form-item>
                  <el-button type="primary" @click="onSubmit('roleRef')">立即创建</el-button>
                  <el-button @click="drawer.status = false">取消</el-button>
                </el-form-item>-->
              </el-form>
            </el-tab-pane>
          </el-tabs>
        </el-aside>
        <el-main style="box-shadow: 0 2px 4px rgba(0, 0, 0, .12), 0 0 6px rgba(0, 0, 0, .04)">
          <el-tabs value="first" style="outline: 0;">
            <el-tab-pane label="功能菜单" name="first">
              <el-tree
                  :data="resourceTree"
                  :default-expand-all="true"
                  show-checkbox
                  ref="tree"
                  node-key="id"
                  style="overflow: auto">
              </el-tree>
            </el-tab-pane>
          </el-tabs>
        </el-main>
      </el-container>
    </el-main>
    <el-footer>
      <el-button type="primary" size="small" @click="onSubmit('roleForm')">立即创建</el-button>
      <el-button size="small" @click="drawer.status = false">取消</el-button>
    </el-footer>
  </el-container>

</template>

<script>
import request from "@/utils/request";

export default {
  props: {
    drawer: Object,
    findByPage: Function
  },
  data() {
    return {
      roleForm: {
        roleName: '',
        roleCode: '',
        roleDesc: '',
        roleResourceList: [],
        isRoleEnabled: '1'
      },
      rules: {
        roleName: [
          {required: true, message: '请填写角色名称', trigger: 'blur'}
        ],
        roleCode: [
          {required: true, message: '请填写角色代码', trigger: 'blur'}
        ],
        roleDesc: [
          {required: true, message: '请填写角色描述', trigger: 'blur'}
        ],
      },
      resourceTree: []
    }
  },
  methods: {
    onSubmit(formName) {

      this.$refs[formName].validate((valid) => {
        if (valid) {
          this.roleForm.roleResourceList = this.$refs.tree.getCheckedKeys();

          return request({
            method: 'post',
            url: '/masterdata/sysRole/createSysRole',
            contentType: 'application/json',
            data: this.roleForm,
            baseURL: 'http://127.0.0.1:8093'
          }).then((response) => {
            // eslint-disable-next-line no-empty
            if (response.data.id !== null) {
              this.$message({
                showClose: true,
                message: '创建成功',
                type: 'success',
              });

              this.$refs[formName].resetFields();
              this.drawer.status = false
              this.findByPage()
            }
          })
        }
      })
    },
    initTree() {
      return request({
        url: `/masterdata/resource/findByTree`,
        baseURL: 'http://127.0.0.1:8093',
        method: 'post',
        contentType: 'application/json',
        data: {}
      }).then((response) => {
        response.data.forEach(father => {
          const fNode = {id: father.id, label: father.resourceName, children: []}
          this.childrenNode(father, fNode)

          this.resourceTree.push(fNode)
        })
      })
    },
    childrenNode(data, fNode) {
      if (data.children) {
        data.children.forEach(children => {
          const cNode = {id: children.id, label: children.resourceName, children: []}
          fNode.children.push(cNode)
          this.childrenNode(children, cNode)
        })
      }
    }
  }, created() {
    this.initTree()
  }
}
</script>

<style scoped>
/deep/ .el-tabs__item:focus.is-active.is-focus:not(:active) {
  -webkit-box-shadow: none;
  box-shadow: none;
}

.el-tree-node__content {
  height: 36px;
}

.el-footer {
  color: #333;
  text-align: right;
}

.el-main {
  color: #333;
}

.el-container {
  height: 100% !important;
}

.el-drawer__body {
  overflow: auto;
}

</style>
