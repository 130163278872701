<template>
  <div>
    <el-form :model="sysRole" ref="roleForm" label-width="80px" class="demo-ruleForm" :size="small">
      <el-row>
        <el-col :span="8">
          <el-form-item label="角色名称">
            <el-input v-model="sysRole.roleName"></el-input>
          </el-form-item>
        </el-col>
        <el-col :span="8">
          <el-form-item label="是否启用">
            <el-select v-model="sysRole.isRoleEnabled" placeholder="是否锁定">
              <el-option label="全部" value=""></el-option>
              <el-option label="是" value="1"></el-option>
              <el-option label="否" value="0"></el-option>
            </el-select>
          </el-form-item>
        </el-col>
      </el-row>

      <el-row>
        <el-button type="primary" icon="el-icon-search" :size="small" @click="findByPage">搜索</el-button>
        <el-button type="primary" icon="el-icon-plus" :size="small" @click="drawer.status=true;drawer.flag='add'">
          新增角色信息
        </el-button>
        <!--<el-button type="primary" :size="small">主要按钮</el-button>
        <el-button type="success" :size="small">成功按钮</el-button>
        <el-button type="info" :size="small">信息按钮</el-button>
        <el-button type="warning" :size="small">警告按钮</el-button>
        <el-button type="danger" :size="small">危险按钮</el-button>-->
      </el-row>
    </el-form>

    <el-table
        :data="tableData"
        :height="tableHeight"
        border
        :size="small"
        style="width: 100%;margin-top: 10px;"
        v-loading="loading"
        ref="topictable"
    >
      <el-table-column
          type="selection"
          width="55">
      </el-table-column>
      <el-table-column
          prop="id"
          label="id"
          width="120">
      </el-table-column>
      <el-table-column
          prop="roleCode"
          label="角色代码"
          show-overflow-tooltip>
      </el-table-column>
      <el-table-column
          prop="roleName"
          label="角色名称"
          show-overflow-tooltip>
      </el-table-column>
      <el-table-column
          prop="isRoleEnabled"
          label="是否激活"
          :formatter="formatIsEnabled"
          show-overflow-tooltip>
      </el-table-column>
      <el-table-column
          prop="createdTime"
          label="创建时间"
          show-overflow-tooltip>
      </el-table-column>
      <el-table-column
          prop="createdBy"
          label="创建人"
          show-overflow-tooltip>
      </el-table-column>
      <el-table-column
          prop="lastModifyTime"
          label="修改时间"
          show-overflow-tooltip>
      </el-table-column>
      <el-table-column
          prop="lastModifyBy"
          label="修改人"
          show-overflow-tooltip>
      </el-table-column>
      <el-table-column
          fixed="right"
          label="操作"
          width="100">
        <!--        <template slot-scope="scope">-->
        <template slot-scope="{row}">
          <el-button type="text" @click="drawer.status=true;drawer.flag='edit';roleId=row.id" :size="small">编辑
          </el-button>
        </template>
      </el-table-column>
    </el-table>

    <el-pagination
        @size-change="handleSizeChange"
        @current-change="handleCurrentChange"
        :current-page="currentPage"
        :page-sizes="[10, 20, 50, 100]"
        :page-size="pageSize"
        layout="total, sizes, prev, pager, next, jumper"
        :total="pageTotal"
        style="margin-top: 10px">
    </el-pagination>

    <el-drawer
        title=""
        :size="`90%`"
        :destroy-on-close="true"
        :visible.sync="drawer.status"
        :show-close="true"
        ref="drawer"
        :with-header="false">
      <AddRole v-if="drawer.flag === 'add'" :drawer="drawer" :findByPage="findByPage" :key="new Date().getTime()"/>
      <EditRole v-else :drawer="drawer" :findByPage="findByPage" :roleId="roleId" :key="new Date().getTime()"/>
    </el-drawer>
  </div>
</template>

<script>
import request from '@/utils/request'
import AddRole from "@/views/role/AddRole";
import EditRole from "@/views/role/EditRole";

export default {
  components: {
    AddRole,
    EditRole
  },
  data() {
    return {
      small: 'small',
      drawer: {
        status: false,
        flag: ''
      },
      tableData: [],
      multipleSelection: [],
      currentPage: 1,
      pageSize: 10,
      loading: true,
      pageTotal: 0,
      tableHeight: null,
      sysRole: {
        roleName: '',
        isRoleEnabled: ''
      },
      roleId: ''
    }
  },

  methods: {
    toggleSelection(rows) {
      if (rows) {
        rows.forEach(row => {
          this.$refs.multipleTable.toggleRowSelection(row);
        });
      } else {
        this.$refs.multipleTable.clearSelection();
      }
    },
    handleSelectionChange(val) {
      this.multipleSelection = val;
    },
    handleSizeChange(val) {
      this.pageSize = val
      this.findByPage()
    },
    handleCurrentChange(val) {
      this.currentPage = val
      this.findByPage()
    },
    onSubmit() {
      this.findByPage();
    },
    formatIsEnabled: function (row) {
      return row.isRoleEnabled === 1 ? '是' : row.isRoleEnabled === 0 ? '否' : '未知'
    },
    findByPage() {
      this.loading = true

      return request({
        url: `/masterdata/sysRole/findByPage/${this.currentPage}/${this.pageSize}`,
        baseURL: 'http://127.0.0.1:8093',
        method: 'post',
        contentType: 'application/json',
        data: this.sysRole
      }).then((response) => {
        this.tableData = response.data.content;
        this.pageTotal = response.data.totalElements;
        this.loading = false;
      })
    }
  }, mounted() {
    this.findByPage()
    this.tableHeight =
        window.innerHeight - this.$refs.topictable.$el.offsetTop - 80;
  },
}
</script>

<style scoped>
.el-select {
  display: inline-block;
  position: relative;
  width: 100% !important;
}
</style>

